// @flow
import * as React from 'react'
import styled from 'react-emotion'
import { graphql } from 'gatsby'

import { Content, Section, Meta } from '../components/Theme'
import Header from '../components/Header'
import Layout from '../components/Layout'

type Props = {
  location: string,
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        title: string,
        slug: string,
        introduction: string,
        methods: Array<any>
      }
    }
  }
}

const IMAGES = [
  require('../assets/about/circle.svg'),
  require('../assets/about/dashed.svg'),
  require('../assets/about/multi.svg'),
  require('../assets/about/longrun.svg')
]

const Method = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 2em;
  div {
    order: ${props => (props.reverse ? 2 : 1)};
    margin-left: ${props => (props.reverse ? '2em' : 0)};
    margin-right: ${props => (props.reverse ? 0 : '2em')};
  }
  > :first-child {
    order: ${props => (props.reverse ? 1 : 2)};
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    div {
      margin-top: 2em;
      margin-left: 0;
      margin-right: 0;
    }
    > :first-child {
      order: ${props => (props.reverse ? 2 : 1)};
    }
  }
`

const Member = styled.li`
  display: flex;
  flex-direction: column;
  width: calc(20% - 0.75em);
  margin-right: 1em;
  flex: 0 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    width: calc(50% - 0.5em);
    max-width: 250px;
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`

const Name = styled.h4`
  margin-bottom: 0.25em;
`
const Role = styled.p`
  font-size: 0.8em;
`

const ProfilePicture = styled.img`
  margin-bottom: 0.5em;
`

export default (props: Props) => {
  const page = props.data && props.data.markdownRemark

  return (
    <Layout location={props.location}>
      <Meta title={page.frontmatter.title} />
      <Header
        title={page.frontmatter.title}
        introduction={page.frontmatter.introduction}
      />
      <Content>
        <Section dangerouslySetInnerHTML={{ __html: page.html }} />

        <Section>
          <h2>How we work</h2>

          {page.frontmatter.methods.map((method, index) => (
            <Method key={`method-${index}`} reverse={index % 2 === 0}>
              <img alt={method.title} src={IMAGES[index]} />
              <div>
                <h4>{method.title}</h4>
                <p>{method.text}</p>
              </div>
            </Method>
          ))}
        </Section>
      </Content>
    </Layout>
  )
}

export const query = graphql`
  query aboutQuery($slug: String = "about") {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        introduction
        methods {
          title
          text
        }
      }
    }
  }
`
